var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "asyent-window",
    {
      attrs: {
        "form-action-method": _vm.formActionWindow,
        "form-actions": _vm.formActions,
        "form-rop": _vm.rop,
        "is-form-submitted": _vm.formSubmitted,
        "is-form-submitting": _vm.formSubmitting,
        "page-path": _vm.pagePath,
        title: _vm.title,
        "title-icon": _vm.formActionIcon
      }
    },
    [
      _c("asyent-form", {
        ref: _vm.formRefAE,
        attrs: {
          "data-error": _vm.dataError,
          "data-loading": _vm.dataLoading,
          errors: _vm.errors,
          "form-action-method": _vm.formActionMethod,
          "form-actions": _vm.formActions,
          "form-rop": _vm.rop,
          "is-form-submitted": _vm.formSubmitted,
          "is-form-submitting": _vm.formSubmitting,
          readonly: _vm.isReadonly,
          reference: _vm.formRef,
          success: _vm.success
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(props) {
              return [
                _vm.rop !== "new"
                  ? _c("asyent-form-info", {
                      attrs: {
                        "no-title": "",
                        color: "cardPrimary",
                        "no-maximize": ""
                      },
                      model: {
                        value: _vm.formData,
                        callback: function($$v) {
                          _vm.formData = $$v
                        },
                        expression: "formData"
                      }
                    })
                  : _vm._e(),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "General", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4", xl: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Code",
                                        name: "code",
                                        "view-mode": _vm.viewMode
                                      },
                                      model: {
                                        value: _vm.formData["code"],
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "code", $$v)
                                        },
                                        expression: "formData['code']"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", lg: "4", xl: "3" } },
                                  [
                                    _c("asyent-form-text", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Name",
                                        name: "name",
                                        "view-mode": _vm.viewMode
                                      },
                                      model: {
                                        value: _vm.formData["name"],
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "name", $$v)
                                        },
                                        expression: "formData['name']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "Configuration", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-timeline",
                                      {
                                        attrs: {
                                          dense:
                                            _vm.$vuetify.breakpoint.mdAndDown,
                                          "align-top":
                                            _vm.$vuetify.breakpoint.mdAndDown
                                        }
                                      },
                                      [
                                        _c(
                                          "v-timeline-item",
                                          {
                                            attrs: {
                                              "fill-dot": "",
                                              color: "primary",
                                              small: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "opposite",
                                                  fn: function() {
                                                    return [
                                                      _c("h2", [
                                                        _vm._v(
                                                          "Allow username in password"
                                                        )
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c(
                                              "asyent-card",
                                              {
                                                attrs: {
                                                  "no-title": "",
                                                  "no-maximize": "",
                                                  outlined: "",
                                                  shaped: ""
                                                }
                                              },
                                              [
                                                _c("br"),
                                                _c("asyent-form-boolean", {
                                                  attrs: {
                                                    "false-value": 0,
                                                    "form-errors":
                                                      _vm.formErrors,
                                                    "form-rules":
                                                      _vm.formFieldsRules,
                                                    "is-disabled-func":
                                                      _vm.isDisabled,
                                                    "true-value": 1,
                                                    label:
                                                      "Allow username in password?",
                                                    name: "hasUsername"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formData[
                                                        "hasUsername"
                                                      ],
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.formData,
                                                        "hasUsername",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formData['hasUsername']"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-timeline-item",
                                          {
                                            attrs: {
                                              "fill-dot": "",
                                              color: "primary",
                                              large: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "opposite",
                                                  fn: function() {
                                                    return [
                                                      _c("h2", [
                                                        _vm._v(
                                                          "Password Expiry (in Days)"
                                                        )
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("asyent-form-digits-slider", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                max: 365,
                                                min: 2,
                                                props: props,
                                                "bg-color": "",
                                                shaped: "",
                                                outlined: "",
                                                label:
                                                  "Password Expiry (in Days)",
                                                name: "forceChangeDays",
                                                unit: "days"
                                              },
                                              model: {
                                                value:
                                                  _vm.formData[
                                                    "forceChangeDays"
                                                  ],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "forceChangeDays",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData['forceChangeDays']"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-timeline-item",
                                          {
                                            attrs: {
                                              "fill-dot": "",
                                              color: "primary",
                                              small: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "opposite",
                                                  fn: function() {
                                                    return [
                                                      _c("h2", [
                                                        _vm._v(
                                                          "Password Warning (in Days)"
                                                        )
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("asyent-form-digits-slider", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                max: 364,
                                                min: 1,
                                                props: props,
                                                "bg-color": "",
                                                shaped: "",
                                                outlined: "",
                                                label:
                                                  "Password Warning (in Days)",
                                                name: "warningChangeDays",
                                                unit: "days"
                                              },
                                              model: {
                                                value:
                                                  _vm.formData[
                                                    "warningChangeDays"
                                                  ],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "warningChangeDays",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData['warningChangeDays']"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-timeline-item",
                                          {
                                            attrs: {
                                              "fill-dot": "",
                                              color: "secondary",
                                              large: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "opposite",
                                                  fn: function() {
                                                    return [
                                                      _c("h2", [
                                                        _vm._v("Minimum Length")
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("asyent-form-digits-slider", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                max: 32,
                                                min: 6,
                                                props: props,
                                                "bg-color": "",
                                                shaped: "",
                                                outlined: "",
                                                label: "Minimum Length",
                                                name: "minLength",
                                                unit: "chars"
                                              },
                                              model: {
                                                value:
                                                  _vm.formData["minLength"],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "minLength",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData['minLength']"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-timeline-item",
                                          {
                                            attrs: {
                                              "fill-dot": "",
                                              color: "secondary",
                                              small: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "opposite",
                                                  fn: function() {
                                                    return [
                                                      _c("h2", [
                                                        _vm._v("Maximum Length")
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("asyent-form-digits-slider", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                max: 32,
                                                min: 6,
                                                props: props,
                                                "bg-color": "",
                                                shaped: "",
                                                outlined: "",
                                                label: "Maximum Length",
                                                name: "maxLength",
                                                unit: "chars"
                                              },
                                              model: {
                                                value:
                                                  _vm.formData["maxLength"],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "maxLength",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData['maxLength']"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-timeline-item",
                                          {
                                            attrs: {
                                              "fill-dot": "",
                                              color: "secondary",
                                              small: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "opposite",
                                                  fn: function() {
                                                    return [
                                                      _c("h2", [
                                                        _vm._v("Min. # Digits")
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("asyent-form-digits-slider", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                max: 32,
                                                props: props,
                                                "bg-color": "",
                                                shaped: "",
                                                outlined: "",
                                                label: "Min. # Digits",
                                                name: "minDigits",
                                                unit: "digits"
                                              },
                                              model: {
                                                value:
                                                  _vm.formData["minDigits"],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "minDigits",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData['minDigits']"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-timeline-item",
                                          {
                                            attrs: {
                                              "fill-dot": "",
                                              color: "secondary",
                                              small: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "opposite",
                                                  fn: function() {
                                                    return [
                                                      _c("h2", [
                                                        _vm._v(
                                                          "Min. # Lowercase Characters"
                                                        )
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("asyent-form-digits-slider", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                max: 32,
                                                props: props,
                                                "bg-color": "",
                                                shaped: "",
                                                outlined: "",
                                                label:
                                                  "Min. # Lowercase Characters",
                                                name: "minLowerCase",
                                                unit: "lowercase chars"
                                              },
                                              model: {
                                                value:
                                                  _vm.formData["minLowerCase"],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "minLowerCase",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData['minLowerCase']"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-timeline-item",
                                          {
                                            attrs: {
                                              "fill-dot": "",
                                              color: "secondary",
                                              small: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "opposite",
                                                  fn: function() {
                                                    return [
                                                      _c("h2", [
                                                        _vm._v(
                                                          "Min. # Uppercase Characters"
                                                        )
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("asyent-form-digits-slider", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                max: 32,
                                                props: props,
                                                "bg-color": "",
                                                shaped: "",
                                                outlined: "",
                                                label:
                                                  "Min. # Uppercase Characters",
                                                name: "minUpperCase",
                                                unit: "uppercase chars"
                                              },
                                              model: {
                                                value:
                                                  _vm.formData["minUpperCase"],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "minUpperCase",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData['minUpperCase']"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-timeline-item",
                                          {
                                            attrs: {
                                              "fill-dot": "",
                                              color: "secondary",
                                              small: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "opposite",
                                                  fn: function() {
                                                    return [
                                                      _c("h2", [
                                                        _vm._v(
                                                          "Min. # Special Characters"
                                                        )
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("asyent-form-digits-slider", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                max: 32,
                                                props: props,
                                                "bg-color": "",
                                                shaped: "",
                                                outlined: "",
                                                label:
                                                  "Min. # Special Characters",
                                                name: "minSpecialChars",
                                                unit: "special chars"
                                              },
                                              model: {
                                                value:
                                                  _vm.formData[
                                                    "minSpecialChars"
                                                  ],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "minSpecialChars",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData['minSpecialChars']"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-timeline-item",
                                          {
                                            attrs: {
                                              "fill-dot": "",
                                              color: "accent",
                                              large: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "opposite",
                                                  fn: function() {
                                                    return [
                                                      _c("h2", [
                                                        _vm._v(
                                                          "Failed Attempts"
                                                        )
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("asyent-form-digits-slider", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                max: 10,
                                                min: 1,
                                                props: props,
                                                "bg-color": "",
                                                shaped: "",
                                                outlined: "",
                                                label: "Failed Attempts",
                                                name: "failedAttempts",
                                                unit: "times"
                                              },
                                              model: {
                                                value:
                                                  _vm.formData[
                                                    "failedAttempts"
                                                  ],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "failedAttempts",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData['failedAttempts']"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-timeline-item",
                                          {
                                            attrs: {
                                              "fill-dot": "",
                                              color: "accent",
                                              small: ""
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "opposite",
                                                  fn: function() {
                                                    return [
                                                      _c("h2", [
                                                        _vm._v(
                                                          "Lockout Duration"
                                                        )
                                                      ])
                                                    ]
                                                  },
                                                  proxy: true
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c("asyent-form-digits-slider", {
                                              attrs: {
                                                "form-errors": _vm.formErrors,
                                                "is-disabled-func":
                                                  _vm.isDisabled,
                                                max: 60,
                                                min: 1,
                                                props: props,
                                                "bg-color": "",
                                                shaped: "",
                                                outlined: "",
                                                label: "Lockout Duration",
                                                name: "lockoutDuration",
                                                unit: "minutes"
                                              },
                                              model: {
                                                value:
                                                  _vm.formData[
                                                    "lockoutDuration"
                                                  ],
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    _vm.formData,
                                                    "lockoutDuration",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "formData['lockoutDuration']"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "asyent-card",
                          { attrs: { title: "Remarks", expandable: "" } },
                          [
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c("asyent-form-textarea", {
                                      attrs: {
                                        "form-errors": _vm.formErrors,
                                        "form-rules": _vm.formFieldsRules,
                                        "is-disabled-func": _vm.isDisabled,
                                        props: props,
                                        label: "Password Policy Remarks",
                                        "no-label": "",
                                        name: "remarks",
                                        "no-resize": "",
                                        rows: 9,
                                        "view-mode": _vm.viewMode
                                      },
                                      model: {
                                        value: _vm.formData["remarks"],
                                        callback: function($$v) {
                                          _vm.$set(_vm.formData, "remarks", $$v)
                                        },
                                        expression: "formData['remarks']"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.formValid,
          callback: function($$v) {
            _vm.formValid = $$v
          },
          expression: "formValid"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }